import { useEffect, useState } from "react";
import { User } from "../helpers/user";

interface UserRowProps {
  rank: number;
  name: string;
  steps: number;
  department: string;
}

function UserRow(props: UserRowProps) {
  const { rank, name, steps, department } = props;
  return (
    <div className="flex flex-col border border-[#E2EFFE] rounded-xl py-5 px-5">
      <div className="flex flex-row text-black items-center justify-between font-medium gap-3">
        <div
          className={`md:text-base h-8 w-8 flex items-center justify-center text-center rounded-full ${
            rank === 1
              ? "bg-[#FFEC80]"
              : rank === 2
              ? "bg-[#C4C4C4]"
              : rank === 3
              ? "bg-[#E3D4AC]"
              : "bg-[#30A4FB] text-white"
          }`}
        >
          {rank}
        </div>
        <div className="md:text-lg text-left flex flex-row flex-1">
          {name}
        </div>
        <div className="md:text-xl font-semibold">
          {steps} <span className="text-base">steps</span>
        </div>
      </div>
      <div className="text-right text-[#94969B] text-base mt-4">
        {department}
      </div>
    </div>
  );
}

export function Leaderboard() {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    fetch("/api/leaderboard")
      .then((r) => r.json())
      .then((u) =>
        setUsers((u["users"] as User[]).sort((a, b) => b.steps - a.steps))
      )
      .catch((e) => console.log("Error while pulling leaderboard", e));
  }, []);

  return (
    <div className="h-full w-full rounded-xl flex flex-col md:px-16">
      <div className="text-xl text-black leading-normal mt-10 mb-6 text-center">
        Leaderboard
      </div>
      <div className="flex flex-col gap-4 pr-2 max-h-[40rem] !overflow-y-auto overflow-x-hidden">
        {
          // TODO: pull scores from backend here
          users.map((user, i) => (
            <UserRow key={i} rank={i + 1} {...user} />
          ))
        }
      </div>
    </div>
  );
}
