import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { User, UserContext } from "./helpers/user";
import { HomePage } from "./pages/homepage";
import { JoinPage } from "./pages/joinpage";
import { LoginPage } from "./pages/loginpage";

function App() {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    // TODO: pull details using login token. need to pass the token
    fetch("/api/user")
      .then((r) => r.json())
      .then((u) => setUser(u));
  }, []);

  return (
    <BrowserRouter>
      <div className="bg-[#F0E9FF] w-screen min-h-screen flex flex-col gap-5 items-center overflow-hidden">
        <UserContext.Provider value={user}>
          <Routes>
            <Route path="/" element={<HomePage />}></Route>
            <Route path="/login" element={<LoginPage />}></Route>
            <Route path="/join" element={<JoinPage />}></Route>
          </Routes>
        </UserContext.Provider>
      </div>
    </BrowserRouter>
  );
}

export default App;
