export function JoinHeader() {
  return (
    <svg
      width="498"
      height="103"
      viewBox="0 0 498 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M527.998 75.0974V101.706C504.137 101.706 484.056 90.4185 478.195 75.0974H527.998Z"
        fill="#FFEC80"
      />
      <path
        d="M528.002 55.2522V75.0974H478.2C477.07 72.1464 476.465 69.0451 476.465 65.8436C476.465 61.089 477.797 56.5498 480.209 52.4014C487.265 54.2702 494.803 55.2522 502.486 55.2522H528.002Z"
        fill="white"
      />
      <path
        d="M528.001 29.9807V55.2521H502.484C494.801 55.2521 487.263 54.2701 480.207 52.4013C487.846 39.2545 506.372 29.9807 528.001 29.9807Z"
        fill="#008AFF"
      />
      <path
        d="M440.073 29.8906L418.438 14.8351H452.011C470.313 14.8351 487.874 9.77482 500.813 0.766567L501.605 0.220459H510.115C519.994 0.220459 528 5.79174 528 12.6607V29.9808C506.371 29.9808 487.845 39.2546 480.206 52.4014C472.013 50.237 464.481 46.8751 458.16 42.4762L440.073 29.8906Z"
        fill="#FFEC80"
      />
      <path
        d="M501.602 0.220459L500.81 0.766567C487.871 9.77482 470.31 14.8351 452.008 14.8351H418.434L397.734 0.430886V0.220459H501.602Z"
        fill="#FF3058"
      />
      <path
        d="M144.77 101.872C137.023 101.872 129.593 99.7273 124.114 95.9146L102.751 81.0494C97.272 77.2367 89.8488 75.0973 82.1016 75.0973H140.623C160.258 75.0973 178.963 69.2805 192.053 59.0999L228.42 30.8325C228.974 30.4016 229.543 29.9758 230.119 29.5649C250.524 39.1895 277.646 46.7348 308.297 50.4022C366.019 57.3112 418.55 48.3481 440.071 29.8906L458.158 42.4761C464.479 46.8751 472.01 50.2369 480.204 52.4013C477.792 56.5497 476.46 61.0889 476.46 65.8436C476.46 69.045 477.065 72.1463 478.195 75.0973H274.464C261.432 75.0973 248.659 77.6525 237.614 82.4673L209.981 94.5217C198.936 99.3365 186.163 101.887 173.131 101.887H144.77V101.872Z"
        fill="#F0E9FF"
      />
      <path
        d="M418.437 14.8352L440.073 29.8907C418.552 48.3482 366.021 57.3114 308.299 50.4024C277.648 46.7349 250.526 39.1896 230.121 29.5651C243.103 20.171 261.045 14.8352 279.851 14.8352H418.437Z"
        fill="#BBDFFD"
      />
      <path
        d="M418.437 14.8351H279.852C261.045 14.8351 243.103 20.1709 230.121 29.565C211.977 21.0026 199.132 10.8019 193.848 0.220459H397.737V0.430886L418.437 14.8351Z"
        fill="white"
      />
      <path
        d="M230.122 29.565C229.546 29.9758 228.977 30.4017 228.422 30.8325L192.055 59.0999C178.966 69.2806 160.26 75.0974 140.626 75.0974H82.104H-30V12.6607C-30 5.79174 -21.9936 0.220459 -12.1152 0.220459H193.841H193.848C199.133 10.8019 211.978 21.0026 230.122 29.565Z"
        fill="#F0E9FF"
      />
      <path
        d="M144.773 101.872V101.887H-29.892H-30V75.0974H82.104C89.8512 75.0974 97.2744 77.2368 102.754 81.0495L124.116 95.9146C129.595 99.7274 137.026 101.872 144.773 101.872Z"
        fill="#FFEC80"
      />
      <path
        d="M82.1016 75.0974C89.8488 75.0974 97.272 77.2368 102.751 81.0495L124.114 95.9146C129.593 99.7274 137.023 101.872 144.77 101.872H162.036"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M-29.8945 101.887H144.77H173.131C186.163 101.887 198.936 99.3366 209.98 94.5218L237.614 82.4674C248.659 77.6526 261.432 75.0974 274.464 75.0974H478.195H527.997"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M528.002 101.706C504.142 101.706 484.061 90.4185 478.2 75.0974C477.07 72.1464 476.465 69.0452 476.465 65.8437C476.465 61.089 477.797 56.5498 480.209 52.4014C487.848 39.2548 506.374 29.981 528.002 29.981"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M-30 75.0974H82.104H140.626C160.26 75.0974 178.966 69.2806 192.055 59.0999L228.422 30.8326C228.977 30.4017 229.546 29.9758 230.122 29.565C243.103 20.1709 261.046 14.8351 279.852 14.8351H418.438H452.011C470.314 14.8351 487.874 9.77483 500.813 0.766555L501.605 0.220447L501.922 0"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M527.997 55.2521H502.48C494.798 55.2521 487.259 54.2701 480.203 52.4013C472.01 50.2369 464.478 46.8751 458.157 42.4762L440.07 29.8907L418.434 14.8351L397.734 0.430908"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M193.838 0.221142C193.802 0.145752 193.766 0.0753892 193.73 0"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
      <path
        d="M440.253 29.7353C440.196 29.7854 440.131 29.8405 440.073 29.8906C418.552 48.3481 366.021 57.3113 308.299 50.4023C277.648 46.7348 250.526 39.1895 230.121 29.565C211.977 21.0026 199.132 10.8019 193.848 0.220459"
        stroke="black"
        stroke-miterlimit="10"
        stroke-linejoin="round"
      />
    </svg>
  );
}
