import { useState } from "react";
import { InputField } from "../components/inputfield";

export function LoginPage() {
  const [email, setEmail] = useState("");

  return (
    <div className="w-full max-w-[15rem] flex flex-col mx-auto gap-8">
      <InputField
        type="email"
        id="email"
        placeholder="Email address"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button
        className="mx-auto py-4 px-8 font-semibold bg-[#008aff] text-white rounded-xl"
        onClick={() => {
          // TODO: login the user by sending them a link
        }}
      >
        Login
      </button>
    </div>
  );
}
