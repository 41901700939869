interface InputFieldProps {
  type?: React.HTMLInputTypeAttribute;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  id?: string;
}

export function InputField(props: InputFieldProps) {
  return (
    <input
      className="
        bg-[#FAFAFF]
        text-[#94969B]
        text-sm rounded-2xl
        focus:ring-blue-500 focus:border-blue-500 block w-full h-full p-4
      "
      {...props}
    />
  );
}
