import { ReferralStars } from "../svgcomponents/ReferralStars";
import { Arrow } from "../svgcomponents/Arrow";

export function Referral(props: any) {
  const { profileuserid } = props;

  function generatecode() {
    if (profileuserid) {
      navigator.clipboard.writeText(profileuserid).then(() => {
        alert('Referral code copied to clipboard. Send it to your Imperial friends!');
      }).catch(() => {
        alert('Sorry, copying to clipboard failed.');
      });
    }
    else {
      alert("Sorry, you are not signed in");
    }
  }

  return (
    <div className="flex flex-col bg-[#30A4FB] md:flex-row min-h-[6rem] rounded-[2.5rem] mt-16">
      <div className="">
        <ReferralStars />
      </div>
      <div className="flex flex-col mx-10 text-center md:text-left md:w-full md:mx-20 z-10">
        <div className="text-white text-3xl md:text-4xl font-bold leading-snug mt-14">
          Invite your friends from Imperial College
        </div>
        <div className="text-white my-6 text-xl">
          If you refer a friend from the school, you will win <b>250 steps</b>.
        </div>
        <div className="text-white mb-14 text-xl">
          If you refer a friend from the same department, you will win{" "}
          <b>500 steps</b>.
        </div>
      </div>

      <div className="md:w-full h-full flex items-center mb-48 md:my-auto mx-10">
        <div className="">
          <Arrow />
        </div>
        <button
          className="bg-white text-xl mx-auto py-4 px-8 md:px-12 text-[#30A4FB] rounded-3xl items-center 
        hover:text-white hover:bg-[#30A4FB] hover:border-2 hover:border-white"
          onClick={generatecode}
        >
          Generate Referral Code
        </button>
      </div>
    </div>
  );
}
