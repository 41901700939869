import { useState } from "react";
import { InputField } from "./inputfield";
import { JoinHeader } from "../svgcomponents/JoinHeader";
import { TerraWidgetResponse } from "terra-api/lib/cjs/API/GenerateWidgetSessions";

export function Join() {
  const [userid, setUserid] = useState("");
  const [refcode, setRefcode] = useState("");

  const handleJoinClick = () => {
    if (userid === refcode) {
      alert("Please use someone else as your referee ;)");
    } else {
      fetch("/api/signup?userid=" + userid + "&refcode=" + refcode).then(
        (res) => {
          if (res.ok) {
            fetch("/api/connect?name=" + userid).then((res) =>
              res
                .json()
                .then(
                  (r: TerraWidgetResponse) => (window.location.href = r.url)
                )
                .catch((e) => console.log("Error while connecting to widget", e))
            );
          } else {
            alert(res);
          }
        }
      );
    }
  };

  return (
    <>
      <JoinHeader />
      <div className="w-[14rem] flex flex-col gap-4 mb-20 mt-16">
        <img
          src="/terra_api_logo.webp"
          alt="terra api logo"
          className="h-6 mb-4 mx-auto"
        />
        <InputField
          type="userid"
          id="userid"
          placeholder="Imperial Shortcode"
          value={userid}
          onChange={(e) => setUserid(e.target.value)}
        />
        <InputField
          type="referral code"
          id="referral code"
          placeholder="Referral Code"
          value={refcode}
          onChange={(e) => setRefcode(e.target.value)}
        />
        <button
          className="mx-auto mt-6 py-4 px-12 disabled:bg-gray-400 bg-[#30A4FB] text-white rounded-3xl"
          disabled={!userid}
          onClick={handleJoinClick}
        >
          Continue
        </button>
      </div>
    </>
  );
}
