export function Arrow() {
  return (
    <svg
      height="6rem"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.43622 67.0943C6.65342 73.6397 10.5458 79.6477 15.6276 83.8412C17.9904 85.7652 20.534 87.3768 23.4093 88.4155C26.0982 89.4159 29.179 89.7392 32.0598 89.8076C33.433 89.8377 34.8144 89.7335 36.1437 89.5992C37.8541 89.4072 39.5644 89.2153 41.2309 88.859C44.4296 88.138 47.3212 86.2658 49.7441 84.1223C52.3095 81.8528 54.4446 79.1254 56.2892 76.2995C58.3146 73.161 60.1702 69.7156 61.3843 66.177C62.6066 62.5041 62.8011 58.4448 61.0221 54.9117C59.4569 51.8502 56.4528 49.8329 52.9745 49.8358C49.8471 49.8332 46.7636 51.3161 44.5572 53.4459C42.148 55.8059 40.9284 58.9937 40.8572 62.3596C40.7204 69.4422 45.2871 76.3272 50.5471 80.6934C53.2662 82.9628 56.4951 84.832 60.0062 85.6131C63.786 86.4105 67.5438 85.8047 70.9946 84.0476C74.6702 82.1425 77.8879 79.3467 80.9632 76.6769C84.2933 73.807 87.4974 70.7946 90.4712 67.5794C96.4408 61.2312 101.802 54.2525 106.434 46.8518C107.571 45.0071 108.626 43.1844 109.66 41.2794C110.572 39.5827 107.968 38.0753 107.056 39.772C99.228 54.0142 88.6402 66.6585 76.0432 76.8881C73.2914 79.1193 70.3148 81.4986 66.8832 82.5019C63.8627 83.3955 60.6777 83.0119 57.8408 81.7868C54.8476 80.4712 52.1943 78.4485 50.0207 76.0776C47.921 73.8192 46.0626 71.1441 44.9743 68.2195C43.908 65.3772 43.4776 62.2772 44.3601 59.3115C45.0754 56.8748 47.0871 54.841 49.3703 53.6595C51.5932 52.5823 54.1395 52.3876 56.1624 53.6977C58.6759 55.3613 59.4708 58.6724 59.2681 61.5449C59.0161 65.2232 57.0482 68.7427 55.3434 71.9277C53.9182 74.5097 52.2025 76.9931 50.2565 79.2736C48.2886 81.4719 46.0603 83.5194 43.3879 84.8927C40.8279 86.192 37.8485 86.4142 35.0253 86.7267C32.3666 86.9954 29.7107 86.779 27.0711 86.2939C24.5878 85.8993 22.5704 84.94 20.4133 83.6217C15.6604 80.6616 11.6338 75.9666 9.55871 70.6629C8.99951 69.2267 8.6267 67.8288 8.31418 66.3268C8.14696 65.5347 7.23147 65.0742 6.49142 65.2716C5.66915 65.4909 5.269 66.3022 5.43622 67.0943Z"
        fill="#FFEC80"
      />
      <path
        d="M81.368 55.0781C87.5049 50.8432 93.9596 47.1399 100.658 43.8561C102.297 43.0666 104.019 42.2552 105.68 41.548C106.316 41.2903 107.042 40.8764 107.774 40.8134C108.289 40.764 108.333 40.9285 108.382 41.4438C108.497 42.2058 108.478 42.9595 108.459 43.7133C108.484 44.6315 108.426 45.5716 108.369 46.5118C108.314 48.2879 108.125 50.0558 107.854 51.8456C107.234 55.7981 106.212 59.7259 104.825 63.4427C104.538 64.18 105.182 65.1092 105.927 65.2626C106.777 65.4764 107.481 64.9803 107.747 64.1607C109.104 60.4961 110.118 56.7025 110.707 52.8021C111.017 50.8259 111.274 48.8195 111.367 46.857C111.452 45.0288 111.597 43.0965 111.384 41.3039C111.156 39.2948 109.903 37.7352 107.798 37.7682C105.88 37.8395 103.978 38.9634 102.286 39.7227C98.5971 41.4112 94.9517 43.2642 91.4324 45.2597C87.4938 47.4992 83.6291 49.8511 79.9125 52.4277C79.2382 52.8717 78.9422 53.7434 79.3561 54.4697C79.6878 55.218 80.7459 55.5523 81.368 55.0781Z"
        fill="#FFEC80"
      />
    </svg>
  );
}
