import { useContext } from "react";
import { Countdown } from "../components/countdown";
import { Welcome } from "../components/welcome";
import { Referral } from "../components/referral";
import { Join } from "../components/join";
import { UserContext } from "../helpers/user";
import { Scribble } from "../svgcomponents/Scribble";
import { Stars } from "../svgcomponents/Stars";
import { MobileSVG } from "../svgcomponents/MobileSVG";
import { Block } from "../svgcomponents/Block";

export function JoinPage() {
  const user = useContext(UserContext);

  return (
    <>
      <div className="w-full z-10">
        <Countdown />
      </div>
      <div className="visible md:invisible absolute z-0 top-48 left-0 right-0 w-screen">
        <MobileSVG />
      </div>
      <div className="invisible md:visible z-0 absolute right-0 top-3 overflow-clip">
        <Scribble />
      </div>
      <div className="flex flex-col w-11/12 md:w-10/12 z-40">
        <Welcome />
      </div>
      <div className="absolute md:left-28 top-36 invisible md:visible align-items-start">
        <Stars />
      </div>
      <div className="invisible md:visible lg:-translate-y-10 w-full align-items-start">
        <Block />
      </div>
      <div className="flex flex-col md:w-[32rem] text-3xl md:text-4xl font-bold text-center mt-32 md:mb-10 mb-7">
        Connect your werable to participate
      </div>
      <div className="flex w-full md:w-10/12 md:bg-[#F7F7F7] items-center rounded-[1rem]">
        <div className="w-[28rem] flex flex-col gap-5 items-center md:my-16 mx-auto overflow-hidden bg-white rounded-3xl">
          <Join />
        </div>
      </div>
      {user ? (
        <div className="flex flex-col w-11/12 md:w-10/12 mt-12 md:mt-4 mb-24">
          <Referral profileuserid={user.userid}/>
        </div>
      ) : (
        <div className="flex flex-col w-11/12 md:w-10/12 mt-12 md:mt-4 mb-24">
          <Referral profileuserid={null} />
        </div>
      )}
    </>
  );
}