export function ReferralStars() {
  return (
    <svg
      height="90%"
      viewBox="0 0 1087 328"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      overflow="hidden"
    >
      <path
        d="M7.89844 148.121V153.605"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.89844 157.152V162.638"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.873 155.379H9.60303"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.19376 155.379H0.923828"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9672 158.572L9.10352 156.633"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.69179 154.125L4.82812 152.188"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9672 152.188L9.10352 154.125"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.69179 156.633L4.82812 158.572"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M495.72 40V44.9535"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M495.72 48.1606V53.1172"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M502.023 46.5586H497.26"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M494.177 46.5586H489.417"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M498.492 49.4436L496.808 47.6912"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M494.628 45.423L492.947 43.6736"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M498.492 43.6736L496.808 45.423"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M494.628 47.6912L492.947 49.4436"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M43.769 13V19.1075"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M43.769 23.0605V29.168"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M51.5408 21.084H45.6685"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M41.8694 21.084H36"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M47.1902 24.6411L45.1138 22.4805"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M42.427 19.6875L40.3506 17.5269"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M47.1902 17.5269L45.1138 19.6875"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M42.427 22.4805L40.3506 24.6411"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1079.81 0.933594V5.11563"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1079.81 10.957V15.1391"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1086.78 8.06641H1082.76"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1077.03 8.06641H1073.01"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1084.18 12.6156L1082.76 11.1367"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1076.9 5.04139L1075.48 3.5625"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1084.26 3.4375L1082.84 4.91639"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1076.82 11.1719L1075.4 12.6508"
        stroke="#0A0A0A"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1029.87 181.203V186.746"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1029.87 194.488V200.031"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1039.11 190.656H1033.78"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1026.19 190.656H1020.86"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1035.67 196.686L1033.78 194.727"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1026.02 186.647L1024.14 184.688"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1035.77 184.52L1033.89 186.479"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1025.91 194.773L1024.03 196.733"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M628.99 4V13.594"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M628.99 26.9998V36.5968"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M644.979 20.3643H635.756"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M622.614 20.3643H613.391"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M639.024 30.8054L635.762 27.4141"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M622.319 13.4253L619.06 10.0339"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M639.195 9.74219L635.936 13.1366"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M622.139 27.4939L618.876 30.8852"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M940.614 326.738C950.894 326.738 959.228 318.066 959.228 307.369C959.228 296.672 950.894 288 940.614 288C930.334 288 922 296.672 922 307.369C922 318.066 930.334 326.738 940.614 326.738Z"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M1028.49 89V95.4696"
        stroke="#FF3058"
        stroke-width="1.6545"
        stroke-miterlimit="10"
      />
      <path
        d="M1028.49 104.508V110.978"
        stroke="#FF3058"
        stroke-width="1.6545"
        stroke-miterlimit="10"
      />
      <path
        d="M1039.27 100.036H1033.06"
        stroke="#FF3058"
        stroke-width="1.6545"
        stroke-miterlimit="10"
      />
      <path
        d="M1024.19 100.036H1017.98"
        stroke="#FF3058"
        stroke-width="1.6545"
        stroke-miterlimit="10"
      />
      <path
        d="M1035.26 107.074L1033.06 104.787"
        stroke="#FF3058"
        stroke-width="1.6545"
        stroke-miterlimit="10"
      />
      <path
        d="M1024 95.3561L1021.8 93.0696"
        stroke="#FF3058"
        stroke-width="1.6545"
        stroke-miterlimit="10"
      />
      <path
        d="M1035.38 92.8733L1033.18 95.1628"
        stroke="#FF3058"
        stroke-width="1.6545"
        stroke-miterlimit="10"
      />
      <path
        d="M1023.88 104.843L1021.68 107.129"
        stroke="#FF3058"
        stroke-width="1.6545"
        stroke-miterlimit="10"
      />
      <path
        d="M579.648 89V93.0727"
        stroke="#FAFAFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M579.648 98.7659V102.839"
        stroke="#FAFAFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M586.437 95.9485H582.521"
        stroke="#FAFAFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M576.94 95.9485H573.023"
        stroke="#FAFAFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M583.907 100.38L582.523 98.9407"
        stroke="#FAFAFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M576.816 93.002L575.43 91.5596"
        stroke="#FAFAFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M583.98 91.4368L582.597 92.8792"
        stroke="#FAFAFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M576.74 98.9746L575.354 100.414"
        stroke="#FAFAFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </svg>
  );
}
