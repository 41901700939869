export function Welcome() {
  return (
    <>
      <div className="flex flex-wrap md:flex-row items-start md:divide-x md:divide-black">
        <div className="items-center">
          <img
            src="/terra_api_logo.webp"
            alt="terra api logo"
            className="h-6 my-2 md:mx-10"
          />
        </div>
        <div className="items-center">
          <img
            src="/imperial_logo.webp"
            alt="imperial college london logo"
            className="h-6 my-2 mx-10"
          />
        </div>
        <div className="items-center">
          <img
            src="/ycombinator.webp"
            alt="ycombinator logo"
            className="h-6 my-5 md:my-2 md:mx-10"
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:py-10 w-full mt-8 md:pl-12">
        <div className="w-full text-center md:text-left">
          <a href="/">
            <button className="h-10 min-w-[12rem] bg-white rounded-full text-[#30A4FB] border border-[#30A4FB] text-lg md:text-xl mt-8">
              Road to Asgard
            </button>
          </a>
          <div className="text-4xl md:text-[3.5rem] font-bold my-6 md:leading-tight leading-normal">
            Highest amount of steps,{" "}
            <span className="text-[#DD425A]">within 7 days, </span>
            wins an Apple Watch
          </div>
          <div className="text-lg md:text-2xl text-[#666666] mt-0.5">
            Join our monthly challenge, only for Imperial College London
            students.
          </div>
          <a href="/join">
            <button className="h-14 min-w-[12rem] rounded-full text-white bg-[#30A4FB] text-xl mt-12">
              Join
            </button>
          </a>
        </div>
        <div className="w-full">
          <div className="z-20">
            <img
              src="/thorcomponent.png"
              className="my-14 md:my-auto mx-auto"
              alt="Thor"
            />
          </div>
        </div>
      </div>
    </>
  );
}

function Rectangle() {
  return (
    <svg
      width="467"
      height="534"
      viewBox="0 0 467 534"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="465"
        height="532"
        rx="19"
        fill="#30A4FB"
        stroke="#0A0A0A"
        stroke-width="2"
      />
    </svg>
  );
}
