import { useState, useEffect } from "react";
import config from '../config';

export function Countdown() {
  const [timeLeft, setTimeLeft] = useState(0);
  const [message, setMessage] = useState("");
  console.log(config.CHALLENGE_END_DATE);

  useEffect(() => {
    const endDate = config.CHALLENGE_END_DATE;
    if (!endDate) {
      console.log("End date not defined");
      return;
    }
    const interval = setInterval(() => {
      const currentTime = new Date();
      const timeLeft =
        (new Date(endDate).getTime() - currentTime.getTime()) / 1000;
      if (timeLeft <= 0) {
        clearInterval(interval);
        setMessage("Challenge has ended");
      } else {
        setTimeLeft(timeLeft);
      }
    }, 1000);
  }, []);

  if (message) {
    return (
      <div className="bg-[#BBDFFD] items-center">
        <p className="p-3 text-center text-base">{message}</p>
      </div>
    );
  } else {
    const days = Math.floor(timeLeft / (60 * 60 * 24));
    const hours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((timeLeft % (60 * 60)) / 60);
    const seconds = Math.floor(timeLeft % 60);

    return (
      <div className="bg-[#BBDFFD] items-center">
        <p className="p-3 text-center text-base">
          The challenge will end in{" "}
          <b>
            {days} days : {hours} hours : {minutes} min : {seconds} sec
          </b>
        </p>
      </div>
    );
  }
}
