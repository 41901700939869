export function Stars() {
  return (
    <svg
      width="355"
      height="524"
      viewBox="0 0 355 524"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      overflow="hidden"
    >
      <path
        d="M42.3306 1V25.4299"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M42.3306 59.564V84.0002"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M84.6979 42.6694H60.2617"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25.4362 42.6694H1"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M68.9159 69.2616L60.2803 60.6196"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.6571 25.0023L16.0215 16.3604"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M69.3749 15.6255L60.7393 24.2674"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.1795 60.8208L15.5376 69.4627"
        stroke="#FF3058"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M317.63 451V477.92"
        stroke="#008BFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M317.63 495.34V522.26"
        stroke="#008BFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M353.26 486.63H326.34"
        stroke="#008BFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M308.92 486.63H282"
        stroke="#008BFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M333.31 502.31L323.79 492.79"
        stroke="#008BFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M311.47 480.47L301.95 470.95"
        stroke="#008BFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M333.31 470.95L323.79 480.47"
        stroke="#008BFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M311.47 492.79L301.95 502.31"
        stroke="#008BFF"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
