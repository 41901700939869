import { createContext } from "react";

export interface User {
  name: string;
  steps: number;
  userid: string;
  department: string;
}

export const UserContext = createContext<User | undefined>(undefined);
