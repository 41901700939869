export function Block() {
  return (
    <svg
      width="588"
      height="120"
      viewBox="0 0 588 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M582.758 98.6795L582.758 98.6799C579.72 106.262 575.299 113.278 569.767 119.5H-0.5V0.5H503.329C549.88 0.5 587.5 33.5709 587.5 74.234C587.5 82.7997 585.832 91.0294 582.758 98.6795Z"
        fill="#FFEC80"
        stroke="black"
      />
    </svg>
  );
}
